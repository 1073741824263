// http://testportalservice.mjosen.no/api/v1/inventory/transactions/types
export const PLANT_INVENTORY_TRANSACTION_RESERVATION = 961700000
export const PLANT_INVENTORY_TRANSACTION_DELIVERY = 961700001
export const PLANT_INVENTORY_TRANSACTION_COLLECTION = 961700002
export const PLANT_INVENTORY_TRANSACTION_RETURN = 961700003
export const PLANT_INVENTORY_TRANSACTION_DEAD_STOCK = 961700004
export const PLANT_INVENTORY_TRANSACTION_CORRECTION = 961700005

export const PLANT_INVENTORY_TRANSACTION_TYPES = [
	PLANT_INVENTORY_TRANSACTION_RESERVATION,
	PLANT_INVENTORY_TRANSACTION_DELIVERY,
	PLANT_INVENTORY_TRANSACTION_COLLECTION,
	PLANT_INVENTORY_TRANSACTION_RETURN,
	PLANT_INVENTORY_TRANSACTION_DEAD_STOCK,
	PLANT_INVENTORY_TRANSACTION_CORRECTION,
]
