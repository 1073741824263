// @ts-nocheck
import { defineStore } from 'pinia'
import {
	PLANT_INVENTORY_TRANSACTION_COLLECTION, PLANT_INVENTORY_TRANSACTION_RESERVATION,
	PLANT_INVENTORY_TRANSACTION_RETURN
} from '~/store/constants/plantInventory';
import {ORDER_STATES_IN_QUEUE} from '~/store/constants/order';
import {useAccountStore} from '~/stores/account';
import {getAxiosConfig} from '~/store/constants/api-url';
import {type DocumentModel, type LedgerModel, OrderApi, type OrderConfirmationModel, type OrderModel} from '~/gen/openapi/portalService';
import {useOrdersStore} from '~/stores/orders';

interface State {
	confirmation: OrderConfirmationModel,
	documents: DocumentModel[],
	documentsErrorMessage: string,
	errorMessageConfirmation: string,
	errorMessage: string,
	invoices: LedgerModel[],
	invoicesErrorMessage: string,
	isLoading: boolean,
	isLoadingConfirmation: boolean,
	order: OrderModel,
	timeLastFetch: number | null,
	version: number,
}

export const useOrderStore = defineStore('order', {
  state: (): State => ({
		confirmation: {},
		documents: [],
		documentsErrorMessage: '',
		errorMessageConfirmation: '',
		errorMessage: '',
		invoices: [],
		invoicesErrorMessage: '',
		isLoading: false,
		isLoadingConfirmation: false,
		order: {},
		timeLastFetch: null,
		version: 0, // Bump this when doing local changes, and use in key to force re-render
	}),
	getters: {
		activeOrderId(state) {
			return state.order && state.order.Id ? state.order.Id : null
		},
		// List an array with transactions that are currently in effect
		// There is no connections between a collection and return, so we need to calculate
		activeTransactions(state) {
			const orderLines = state.order.orderLines || []
			const transactionsByLine = {}

			for (let i = 0; i < orderLines.length; i++) {
				const orderLine = orderLines[i]
				const transactions =
					(orderLine.Inventory && orderLine.Inventory.Transactions) || []

				// Get all collected transactions
				const collectedTransactions = JSON.parse(
					JSON.stringify(
						transactions.filter(
							(transaction) =>
								transaction.Type === PLANT_INVENTORY_TRANSACTION_COLLECTION
						)
					)
				)

				// Get all returned transactions
				const returnedTransactions = JSON.parse(
					JSON.stringify(
						transactions.filter(
							(transaction) =>
								transaction.Type === PLANT_INVENTORY_TRANSACTION_RETURN
						)
					)
				)

				// Group transactions of same product, location and lot
				let groupedTransactions = []
				collectedTransactions.forEach((transaction) => {
					const index = groupedTransactions.findIndex((groupedTransaction) =>
						transactionMatch(groupedTransaction, transaction)
					)

					if (index > -1) {
						// If already a transaction with the same ids, increase the quantity
						groupedTransactions[index].Quantity += transaction.Quantity || 0
					} else {
						// Else add the transaction to the grouped array
						groupedTransactions.push(transaction)
					}
				})

				// But wait, we might already have returned some plants, these need to be subtracted
				returnedTransactions.forEach((returnedTransaction) => {
					const index = groupedTransactions.findIndex((groupedTransaction) =>
						transactionMatch(groupedTransaction, returnedTransaction)
					)

					if (index > -1) {
						groupedTransactions[index].Quantity -=
							returnedTransaction.Quantity || 0
					}
				})

				// No need to list transactions with a Quantity of 0
				groupedTransactions = groupedTransactions.filter(
					(groupedTransaction) => groupedTransaction.Quantity > 0
				)

				// Group transactions by location
				const locations = []
				groupedTransactions.forEach((transaction) => {
					const index = locations.findIndex(
						(location) => location.Id === transaction.InventoryLocationId
					)

					if (index > -1) {
						locations[index].Transactions.push(transaction)
					} else {
						locations.push({
							Id: transaction.InventoryLocationId,
							Name: transaction.InventoryLocationName,
							Transactions: [transaction],
						})
					}
				})

				transactionsByLine[orderLine.Id] = locations
			}

			function transactionMatch(a, b) {
				if (!a || !b) {
					return false
				}
				return (
					a.TreePlantProductId === b.TreePlantProductId &&
					a.InventoryLocationId === b.InventoryLocationId &&
					a.ProductLotId === b.ProductLotId
				)
			}

			return transactionsByLine
		},
		getActiveTransactions(): (lineId: string) => any {
			return (lineId) => {
				return this.activeTransactions[lineId]
			}
		},
		isInQueue(state) {
			if (!state.order) {
				return false
			}

			return ORDER_STATES_IN_QUEUE.includes(state.order.Status)
		},
		orderType(state) {
			return state.order && state.order.Type
			? useOrdersStore().getType(state.order.Type)
				: null
		},
	},
	actions: {
		fetch(id) {
			const accountStore = useAccountStore()
			const accountId = accountStore.activeAccount
			if (!id) {
				return new Promise((resolve, reject) => {
					reject('No id!')
				})
			}

			const {$config, $axios} = useNuxtApp()
			const ordersApi = new OrderApi(undefined, $config.public.apiBaseHost, $axios)

			// Cache duration 5 min
			const cacheDuration = 5 * 60 * 1000 // 5 minutes * 60 seconds * 1000 milliseconds
			const isExpired =
				!this.timeLastFetch ||
				Date.now() - new Date(this.timeLastFetch).getTime() > cacheDuration

			if (!isExpired && this.order && this.order.Id === id) {
				return Promise.resolve()
			}

			this.timeLastFetch = Date.now()
			this.errorMessage = ''
			this.documentsErrorMessage = ''
			this.isLoading = true

			const getData = new Promise((resolve, reject) => {
				ordersApi.orderGetOrder(accountId, id).then(({data}) => {
						this.order = data
						resolve(data)
					})
					.catch((error) => {
						this.errorMessage = `${error.response?.status}: ${error.response?.statusText}`
						reject(error)
					})
			})

			const getDocuments = new Promise((resolve, reject) => {
				ordersApi.orderGetOrderDocuments(accountId, id).then(({data}) => {
						this.documents = data
						resolve(data)
					})
					.catch((error) => {
						this.documentsErrorMessage = `${error.response?.status}: ${error.response?.statusText}`
						reject(error)
					})
			})

			const getInvoices = new Promise((resolve, reject) => {
				ordersApi.orderGetOrderInvoices(accountId, id).then(({data}) => {
						this.invoices = data
						resolve(data)
					})
					.catch((error) => {
						this.invoicesErrorMessage = `${error.response?.status}: ${error.response?.statusText}`
						reject(error)
					})
			})

			return Promise.all([getData, getDocuments, getInvoices])
				.catch((error) => {
					return Promise.reject(error.response)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		async fetchConfirmation() {
			const accountStore = useAccountStore()
			const accountId = accountStore.activeAccount
			const orderId = this.order && this.order.Id

			if (!orderId) {
				return false
			}

			const {$config, $axios} = useNuxtApp()
			const ordersApi = new OrderApi(undefined, $config.public.apiBaseHost, $axios)

			let result: OrderConfirmationModel = {}
			this.isLoadingConfirmation = true
			this.errorMessageConfirmation = ''

			try {
				result = (await ordersApi.orderGetOrderConfirmation(accountId, orderId)).data
				if (result && typeof result === 'object') {
					this.confirmation = result
				} else {
					this.confirmation = {}
				}
			} catch (error: any) {
				console.error(error)
				this.errorMessageConfirmation = error.statusText
			} finally {
				this.isLoadingConfirmation = false
			}

			return result
		},
		reset() {
			this.order = {}
			this.documents = []
			this.invoices = []

			this.errorMessage = ''
			this.documentsErrorMessage = ''
			this.invoicesErrorMessage = ''
		},
		resetFetchingTime() {
			this.timeLastFetch = null
		},
		async setTeam({ orderId, teamId }) {
			const accountStore = useAccountStore()
			const accountId = accountStore.activeAccount
			let result

			if (!accountId || !orderId) {
				return false
			}

			const {$config, $axios} = useNuxtApp()
			const ordersApi = new OrderApi(undefined, $config.public.apiBaseHost, $axios)

			if (!teamId) {
				teamId = 'null'
			}

			try {
				result = (await ordersApi.orderUpdateOrderTeam(accountId, orderId, teamId)).data

				if (result && typeof result === 'object') {
					this.order = result
				}

				// The team is visible in the orders list
				// Therefor we reset the cache
				useOrdersStore().resetFetchingTime()
			} catch (error) {
				console.error(error)
			}

			return result
		},
		update(change: OrderModel, preSettingState = false) {
			const accountStore = useAccountStore()
			const accountId = accountStore.activeAccount
			const order = this.order
			const orderId = order && order.Id
			const mergedOrder = Object.assign({} as OrderModel, order, change)

			if (
				!orderId ||
				!accountId ||
				JSON.stringify(order) === JSON.stringify(mergedOrder)
			) {
				return Promise.resolve(false)
			}

			const {$config, $axios} = useNuxtApp()
			const ordersApi = new OrderApi(undefined, $config.public.apiBaseHost, $axios)

			// Update order before ajax
			if (preSettingState) {
				this.order = mergedOrder
			}

			return new Promise((resolve, reject) => {
				ordersApi.orderUpdateOrder(
						accountId,
						orderId,
						change,
					getAxiosConfig()
					)
					.then(({data}) => {
						if (preSettingState) {
							useOrdersStore().updateOrder(data)
						}
						this.order = mergedOrder
						resolve(data)
					})
					.catch((error) => {
						this.errorMessage = `${error.response?.status}: ${error.response?.statusText}`
						reject(error)
					})
			})
		},
		// Same as above but w/out pre-setting the data
		updateRequest(change: OrderModel) {
			return this.update(change, false)
		},
		updateTransactionSums(lineId: string) {
			if (
				!lineId ||
				!this.order.orderLines ||
				!Array.isArray(this.order.orderLines)
			) {
				return
			}

			const keyMap = {
				SumReservedVolume: PLANT_INVENTORY_TRANSACTION_RESERVATION,
				SumCollectedVolume: PLANT_INVENTORY_TRANSACTION_COLLECTION,
				SumReturnedVolume: PLANT_INVENTORY_TRANSACTION_RETURN,
			}
			const keyMapKeyArray = Object.keys(keyMap)
			const orderLines = this.order.orderLines
			const lineIndex = orderLines.findIndex(
				(existingLine) => existingLine.Id === lineId
			)

			if (lineIndex > -1) {
				const inventory = orderLines[lineIndex].Inventory
				const transactions = inventory.Transactions

				for (let i = 0; i < keyMapKeyArray.length; i++) {
					const key = keyMapKeyArray[i] // inventory sum key
					const type = keyMap[key] // transaction type
					const transactionsOfTypeArray =
						Array.isArray(transactions) &&
						transactions.length &&
						transactions.filter((transaction) => transaction.Type === type)

					const sum =
						(transactionsOfTypeArray &&
							transactionsOfTypeArray.length > 0 &&
							transactionsOfTypeArray.reduce((accumulator, transaction) => {
								return accumulator + (transaction.Quantity || 0)
							}, 0)) ||
						0

					inventory[key] = sum
				}

				this.version++
			}
		},
		addDocument(document: DocumentModel) {
			if (
				!document ||
				!document.DocId ||
				this.documents.findIndex(
					(existingDocument) => existingDocument.DocId === document.DocId
				) > -1
			) {
				return
			}
			this.documents = [...this.documents, document]
		},
		setLine(line) {
			if (
				!this.order ||
				!this.order.orderLines ||
				!Array.isArray(this.order.orderLines) ||
				!line ||
				!line.Id
			) {
				return
			}

			const lineIndex = this.order.orderLines.findIndex(
				(existingLine) => existingLine.Id === line.Id
			)

			if (lineIndex > -1) {
				this.order.orderLines[lineIndex] = line
			}
		},
		removeDocument(documentId: string) {
			this.documents = this.documents.filter(
				(document) => document.DocId !== documentId
			)
		},
		setData(data: OrderModel) {
			this.order = data
		},
		setTransaction({ lineId, transaction }){
			if (
				!lineId ||
				!transaction ||
				!this.order.orderLines ||
				!Array.isArray(this.order.orderLines)
			) {
				return
			}

			const orderLines = this.order.orderLines
			const lineIndex = orderLines.findIndex(
				(existingLine) => existingLine.Id == lineId
			)

			if (lineIndex > -1) {
				const transactionIndex = orderLines[
					lineIndex
					].Inventory.Transactions.findIndex(
					(existingTransaction) => existingTransaction.Id === transaction.Id
				)

				if (transactionIndex > -1) {
					this.order.orderLines[lineIndex].Inventory.Transactions[transactionIndex] = transaction
				} else {
					this.order.orderLines[lineIndex].Inventory.Transactions[this.order.orderLines[lineIndex].Inventory.Transactions.length] = transaction
				}
			}
		}
	}

})
